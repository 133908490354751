import { ComposerTranslation } from 'vue-i18n'

import { LicenseType } from '@/types/company'
import {
    ActivityLog,
    ActivityLogDropdownOption,
    ActivityLogIcon,
    ActivityLogType,
    LogConfig,
} from '@/types/activity-log'

export const logConfig: Record<ActivityLogType, LogConfig> = {
    [ActivityLogType.CompanyUpdated]: {
        name: 'entityUpdated',
        entity: 'company',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.metadata' },
    },
    [ActivityLogType.LocationCreated]: {
        name: 'entityCreated',
        entity: 'location',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        route: { name: 'settings.locations' },
    },
    [ActivityLogType.LocationUpdated]: {
        name: 'entityUpdated',
        entity: 'location',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.locations' },
    },
    [ActivityLogType.LocationDeleted]: {
        name: 'entityDeleted',
        entity: 'location',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CustomerCreated]: {
        name: 'entityCreated',
        entity: 'customer',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'packaging.customers.show',
            params: { id: log.loggableId },
        }),
    },
    [ActivityLogType.CustomerUpdated]: {
        name: 'entityUpdated',
        entity: 'customer',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'packaging.customers.show',
            params: { id: log.loggableId },
        }),
    },
    [ActivityLogType.CustomerCompanyUpdated]: {
        name: 'entityUpdated',
        entity: 'customerCompany',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: customerRoute,
    },
    [ActivityLogType.EmployeeCreated]: {
        name: 'entityCreated',
        entity: 'employee',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'settings.employees',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.EmployeeUpdated]: {
        name: 'entityUpdated',
        entity: 'employee',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'settings.employees',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.EmployeeRemoved]: {
        name: 'entityDeleted',
        entity: 'employee',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.ProductAdded]: {
        name: 'entityAdded',
        entity: 'product',
        color: 'green-500',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Created,
        route: { name: 'packaging.products' },
    },
    [ActivityLogType.ProductUpdated]: {
        name: 'entityUpdated',
        entity: 'product',
        color: 'blue-400',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'packaging.products' },
    },
    [ActivityLogType.ProductRemoved]: {
        name: 'entityDeleted',
        entity: 'product',
        color: 'yellow-500',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.ProductOrderUpdated]: {
        name: 'entityUpdated',
        entity: 'productOrder',
        color: 'blue-500',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Reorder,
        route: { name: 'packaging.products' },
    },
    [ActivityLogType.CustomerLocationCreated]: {
        name: 'entityCreated',
        entity: 'customerLocation',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        route: customerRoute,
    },
    [ActivityLogType.CustomerLocationUpdated]: {
        name: 'entityUpdated',
        entity: 'customerLocation',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: customerRoute,
    },
    [ActivityLogType.CustomerLocationDeleted]: {
        name: 'entityDeleted',
        entity: 'customerLocation',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CustomerMerged]: {
        name: 'entityMerged',
        entity: 'customer',
        color: 'blue-400',
        icon: ActivityLogIcon.Reorder,
    },
    [ActivityLogType.PODPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'podPreferences',
        color: 'blue-400',
        moduleLicense: LicenseType.PODModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.MessageTemplateUpdated]: {
        name: 'entityUpdated',
        entity: 'messageTemplate',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.message-templates' },
    },
    [ActivityLogType.ScheduledStatementEnabled]: {
        name: 'entityEnabled',
        entity: 'scheduledStatement',
        color: 'green-500',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.ScheduledStatementUpdated]: {
        name: 'entityUpdated',
        entity: 'scheduledStatement',
        color: 'blue-400',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.ScheduledStatementDisabled]: {
        name: 'entityDisabled',
        entity: 'scheduledStatement',
        color: 'yellow-500',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CustomerBalanceLimitUpdated]: {
        name: 'entityUpdated',
        entity: 'customerBalanceLimit',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DriverReportPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'driverReportPreferences',
        color: 'blue-400',
        moduleLicense: LicenseType.DriverReportModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.TemplateCreated]: {
        name: 'entityCreated',
        entity: 'template',
        color: 'green-500',
        moduleLicense: LicenseType.DamageReportModule,
        icon: ActivityLogIcon.Created,
        route: { name: 'dmr.templates' },
    },
    [ActivityLogType.TemplateUpdated]: {
        name: 'entityUpdated',
        entity: 'template',
        color: 'blue-400',
        moduleLicense: LicenseType.DamageReportModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'dmr.templates' },
    },
    [ActivityLogType.TemplateDeleted]: {
        name: 'entityDeleted',
        entity: 'template',
        color: 'yellow-500',
        moduleLicense: LicenseType.DamageReportModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.TransportObjectCreated]: {
        name: 'entityCreated',
        entity: 'transportObject',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        moduleLicense: LicenseType.DamageReportModule,
        route: (log: ActivityLog) => ({
            name: 'transport-objects.show',
            params: { transportObjectId: log.loggableId },
        }),
    },
    [ActivityLogType.TransportObjectUpdated]: {
        name: 'entityUpdated',
        entity: 'transportObject',
        color: 'blue-400',
        moduleLicense: LicenseType.DamageReportModule,
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'transport-objects.show',
            params: { transportObjectId: log.loggableId },
        }),
    },
    [ActivityLogType.TransportObjectDeleted]: {
        name: 'entityDeleted',
        entity: 'transportObject',
        color: 'yellow-500',
        moduleLicense: LicenseType.DamageReportModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.EmployeeRoleCreated]: {
        name: 'entityCreated',
        entity: 'employeeRole',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.EmployeeRoleUpdated]: {
        name: 'entityUpdated',
        entity: 'employeeRole',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.EmployeeRoleDeleted]: {
        name: 'entityDeleted',
        entity: 'employeeRole',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.AlarmNotificationCreated]: {
        name: 'entityCreated',
        entity: 'alarmNotification',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'settings.alarms',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.AlarmNotificationUpdated]: {
        name: 'entityUpdated',
        entity: 'alarmNotification',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'settings.alarms',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.AlarmNotificationDeleted]: {
        name: 'entityDeleted',
        entity: 'alarmNotification',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.DamageReportPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'damageReportPreferences',
        color: 'blue-400',
        moduleLicense: LicenseType.DamageReportModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.SettlementTemplateCreated]: {
        name: 'entityCreated',
        entity: 'settlementTemplate',
        color: 'green-500',
        moduleLicense: LicenseType.DriverReportModule,
        icon: ActivityLogIcon.Created,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.SettlementTemplateUpdated]: {
        name: 'entityUpdated',
        entity: 'settlementTemplate',
        color: 'blue-400',
        moduleLicense: LicenseType.DriverReportModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.SettlementTemplateDeleted]: {
        name: 'entityDeleted',
        entity: 'settlementTemplate',
        color: 'yellow-500',
        moduleLicense: LicenseType.DriverReportModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.WebRoleCreated]: {
        name: 'entityCreated',
        entity: 'webRole',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.WebRoleUpdated]: {
        name: 'entityUpdated',
        entity: 'webRole',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.WebRoleDeleted]: {
        name: 'entityDeleted',
        entity: 'webRole',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.PackagingPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'packagingPreferences',
        color: 'blue-400',
        moduleLicense: LicenseType.PackagingModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.DeliveryManagementHubCreated]: {
        name: 'entityCreated',
        entity: 'hub',
        color: 'green-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'dm.hubs',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.DeliveryManagementHubUpdated]: {
        name: 'entityUpdated',
        entity: 'hub',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'dm.hubs',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.DeliveryManagementShipperHubUpdated]: {
        name: 'entityUpdated',
        entity: 'shipperHub',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'dm.hubs',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.DeliveryManagementTourCreated]: {
        name: 'entityCreated',
        entity: 'tour',
        color: 'green-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'dm.tours.show',
            params: { tourId: log.loggableId },
        }),
    },
    [ActivityLogType.DeliveryManagementTourUpdated]: {
        name: 'entityUpdated',
        entity: 'tour',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'dm.tours.show',
            params: { tourId: log.loggableId },
        }),
    },
    [ActivityLogType.DeliveryManagementTourDeleted]: {
        name: 'entityDeleted',
        entity: 'tour',
        color: 'yellow-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.DeliveryManagementShipmentCreated]: {
        name: 'entityCreated',
        entity: 'shipment',
        color: 'green-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'dm.shipments.show',
            params: { shipmentId: log.loggableId },
        }),
    },
    [ActivityLogType.DeliveryManagementShipmentUpdated]: {
        name: 'entityUpdated',
        entity: 'shipment',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'dm.shipments.show',
            params: { shipmentId: log.loggableId },
        }),
    },
    [ActivityLogType.DeliveryManagementColliCreated]: {
        name: 'entityCreated',
        entity: 'colli',
        color: 'green-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Created,
        route: (log: ActivityLog) => ({
            name: 'dm.collis',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.DeliveryManagementColliUpdated]: {
        name: 'entityUpdated',
        entity: 'colli',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: (log: ActivityLog) => ({
            name: 'dm.collis',
            query: { query: log.data?.name },
        }),
    },
    [ActivityLogType.DeliveryManagementColliDeleted]: {
        name: 'entityDeleted',
        entity: 'colli',
        color: 'yellow-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.DeliveryManagementPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'deliveryManagementPreferences',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'settings.licenses' },
    },
    [ActivityLogType.DeliveryManagementDeliveryDescriptionCreated]: {
        name: 'entityCreated',
        entity: 'deliveryDescription',
        color: 'green-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Created,
        route: { name: 'dm.delivery-descriptions' },
    },
    [ActivityLogType.DeliveryManagementDeliveryDescriptionUpdated]: {
        name: 'entityUpdated',
        entity: 'deliveryDescription',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
        route: { name: 'dm.delivery-descriptions' },
    },
    [ActivityLogType.DeliveryManagementDeliveryDescriptionDeleted]: {
        name: 'entityDeleted',
        entity: 'deliveryDescription',
        color: 'yellow-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.TourOptimized]: {
        name: 'tourOptimized',
        entity: 'tour',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.TourETACalculated]: {
        name: 'etaCalculated',
        entity: 'tour',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipmentsScannedOnOtherTour]: {
        name: 'shipmentsScannedOnOtherTour',
        entity: 'tour',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipmentsScannedOnTour]: {
        name: 'shipmentsScannedOnTour',
        entity: 'tour',
        color: 'blue-400',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipmentsRemovedFromTour]: {
        name: 'shipmentsRemovedFromTour',
        entity: 'tour',
        color: 'yellow-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Removed,
        route: (log: ActivityLog) => ({
            name: 'dm.tours.show',
            params: { tourId: log.loggableId },
        }),
    },
    [ActivityLogType.HaulierEmployeeCreated]: {
        name: 'entityCreated',
        entity: 'haulier',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.HaulierEmployeeUpdated]: {
        name: 'entityUpdated',
        entity: 'haulier',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.HaulierEmployeeRemoved]: {
        name: 'entityRemoved',
        entity: 'haulier',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CompanyTagDeleted]: {
        name: 'entityRemoved',
        entity: 'tag',
        color: 'yellow-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CompanyTagAdded]: {
        name: 'entityAdded',
        entity: 'tag',
        color: 'green-500',
        moduleLicense: LicenseType.DeliveryManagementModule,
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.ShipmentCostCalculated]: {
        name: 'shipmentCostCalculated',
        entity: 'tag',
        color: 'green-500',
        moduleLicense: LicenseType.ShipmentCostCalculation,
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.AlarmTicketNumberUpdated]: {
        name: 'entityUpdated',
        entity: 'ticketNumber',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
}

function customerRoute(log: ActivityLog) {
    return log.data?.customerId
        ? {
              name: 'packaging.customers.show',
              params: { id: log.data?.customerId },
          }
        : {}
}

export function translateLogType(
    t: ComposerTranslation,
    logType: ActivityLogType,
): ActivityLogDropdownOption {
    const { name, entity } = logConfig[logType]
    const translation = t(name, { entity: t(entity) })
    return { label: translation, value: logType }
}

export function translateAndFormatFields(
    t: ComposerTranslation,
    data: Record<string, unknown>,
): string {
    const translatedData: Record<string, string> = {}
    for (const key in data) {
        const value = data[key]
        let translatedKey = key

        if (key in translationsForFields) {
            translatedKey = t(translationsForFields[key]) || key
        } else {
            translatedKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
            translatedKey = t(translatedKey)
        }

        translatedData[translatedKey] =
            typeof value === 'string' || typeof value === 'number' ? String(value) : ''
    }

    return Object.entries(translatedData)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n')
}

// This manages the translations for the fields in the activity log. We will have to add more fields as we go.
export const translationsForFields: Record<string, string> = {
    driver_user_id: 'driver',
    company_id: 'company',
    hub_id: 'hub',
    app_role_id: 'appRole',
    web_role_id: 'webRole',
    settlement_template_id: 'settlementTemplate',
    template_id: 'template',
    template_field_id: 'templateField',
    report_id: 'report',
    category_id: 'category',
    sending_location_id: 'sendingLocation',
    receiving_location_id: 'receivingLocation',
    shipper_location_id: 'shipperLocation',
    haulier_company_id: 'haulier',
    shipper_company_id: 'shipper',
    transport_object_ids: 'vehiclesAndUnits',
    shipment_ids: 'shipments',
    driver_ids: 'drivers',
    haulier_hidden: 'haulierHidden',
    drawing_path: 'drawing',
    haulier_ids: 'hauliers',
    ticket_number: 'ticketNumber',
}

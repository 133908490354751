import { HTMLAttributes } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import { Model, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'
import { LicenseType, MinimalCompany } from '@/types/company'
import { DropdownOption } from '@/types/inputs'

export enum ActivityLogType {
    CompanyUpdated = 0,
    LocationCreated = 1,
    LocationUpdated = 2,
    LocationDeleted = 3,
    CustomerCreated = 4,
    CustomerUpdated = 5,
    CustomerCompanyUpdated = 6,
    EmployeeCreated = 7,
    EmployeeUpdated = 8,
    EmployeeRemoved = 9,
    ProductAdded = 10,
    ProductUpdated = 11,
    ProductRemoved = 12,
    ProductOrderUpdated = 13,
    CustomerLocationCreated = 14,
    CustomerLocationUpdated = 15,
    CustomerLocationDeleted = 16,
    CustomerMerged = 17,
    PODPreferencesUpdated = 18,
    MessageTemplateUpdated = 19,
    ScheduledStatementEnabled = 20,
    ScheduledStatementUpdated = 21,
    ScheduledStatementDisabled = 22,
    CustomerBalanceLimitUpdated = 23,
    DriverReportPreferencesUpdated = 24,
    TemplateCreated = 25,
    TemplateUpdated = 26,
    TemplateDeleted = 27,
    TransportObjectCreated = 28,
    TransportObjectUpdated = 29,
    TransportObjectDeleted = 30,
    EmployeeRoleCreated = 31,
    EmployeeRoleUpdated = 32,
    EmployeeRoleDeleted = 33,
    AlarmNotificationCreated = 34,
    AlarmNotificationUpdated = 35,
    AlarmNotificationDeleted = 36,
    DamageReportPreferencesUpdated = 37,
    SettlementTemplateCreated = 38,
    SettlementTemplateUpdated = 39,
    SettlementTemplateDeleted = 40,
    WebRoleCreated = 41,
    WebRoleUpdated = 42,
    WebRoleDeleted = 43,
    PackagingPreferencesUpdated = 44,
    DeliveryManagementHubCreated = 45,
    DeliveryManagementHubUpdated = 46,
    DeliveryManagementShipperHubUpdated = 47,
    DeliveryManagementTourCreated = 48,
    DeliveryManagementTourUpdated = 49,
    DeliveryManagementTourDeleted = 50,
    DeliveryManagementShipmentCreated = 51,
    DeliveryManagementShipmentUpdated = 52,
    DeliveryManagementColliCreated = 53,
    DeliveryManagementColliUpdated = 54,
    DeliveryManagementColliDeleted = 55,
    DeliveryManagementPreferencesUpdated = 56,
    DeliveryManagementDeliveryDescriptionCreated = 57,
    DeliveryManagementDeliveryDescriptionUpdated = 58,
    DeliveryManagementDeliveryDescriptionDeleted = 59,
    TourOptimized = 60,
    TourETACalculated = 61,
    DeliveryManagementShipmentsScannedOnOtherTour = 62,
    DeliveryManagementShipmentsScannedOnTour = 63,
    DeliveryManagementShipmentsRemovedFromTour = 64,
    HaulierEmployeeCreated = 65,
    HaulierEmployeeUpdated = 66,
    HaulierEmployeeRemoved = 67,
    CompanyTagDeleted = 68,
    CompanyTagAdded = 69,
    ShipmentCostCalculated = 73,
    AlarmTicketNumberUpdated = 80,
}

export enum ActivityLogIcon {
    Updated,
    Created,
    Removed,
    Reorder,
    Warning,
}

export interface LogConfig {
    name: string
    entity: string
    moduleLicense?: LicenseType
    color: HTMLAttributes['class']
    icon: ActivityLogIcon
    route?: RouteLocationRaw | ((log: ActivityLog) => RouteLocationRaw)
}

export interface ActivityLog extends Model {
    id: string
    company: MinimalCompany
    user: MinimalUser
    type: ActivityLogType
    loggableId: uuid
    loggableType: string
    createdAt: string
    data: {
        fields: Model
        previousTourIds?: uuid[]
        newTourId?: uuid
        shipmentIds?: uuid[]
        customerId?: uuid
        name?: string
    } | null
    previousData: { fields: Model } | null
}

export interface ActivityLogDropdownOption extends DropdownOption {
    value: ActivityLogType
}

import { MinimalUser } from '@/types/user'
import { LicenseType, MinimalCompany } from '@/types/company'
import { uuid, Model } from '@/types/general'
import { TransportObject, IncidentType } from '@/types/transport-object'
import { DeviationState, MinimalCompanyTag } from '@/types/delivery-management'

export enum AlarmType {
    DamageReportIncident = 0,
    DamageReportTireThread = 1,
    DamageReportField = 2,
    PackagingCustomerBalanceLimit = 3,
    DeliveryDeviationCreated = 4,
    DeliveryTicketCreated = 5,
    DeliveryTicketUpdated = 6,
    DamageReportIncorrectTransportObject = 7,
    DeliveryDeviationMankoColliScan = 8,
}

export interface Alarm extends Model {
    id: uuid
    licenseId: LicenseType
    type: AlarmType
    description: Record<string, unknown>
    alarmableId: uuid
    alarmableType: string
    comment: string
    createdAt: string
    resolvedAt: string | null
    resolvedBy: MinimalUser | null
    user: MinimalUser | null
    updatedByUser: MinimalUser | null
    mutedAt: string | null
    tags?: MinimalCompanyTag[]
    assignedUser: MinimalUser | null
    ticketNumber: string | null
}

export interface IncidentAlarm extends Alarm {
    licenseId: LicenseType.DamageReportModule
    type: AlarmType.DamageReportIncident
    alarmableType: 'App\\Models\\DamageReports\\Report'
    description: { incidentId: uuid; masterTemplateSectionId: uuid }
    transportObject: TransportObject
    incidentType: IncidentType
    masterTemplateSectionName: string
}

export interface TireThreadAlarm extends Alarm {
    licenseId: LicenseType.DamageReportModule
    type: AlarmType.DamageReportTireThread
    alarmableType: 'App\\Models\\DamageReports\\Report'
    description: { tireThreadThreshold: number }
    transportObject: TransportObject
}

export interface TemplateFieldAlarm extends Alarm {
    licenseId: LicenseType.DamageReportModule
    type: AlarmType.DamageReportField
    alarmableType: 'App\\Models\\DamageReports\\Report'
    description: { templateFieldId: uuid; reportFieldId: uuid }
    transportObject: TransportObject
    templateFieldName: string
}

export interface IncorrectTransportObjectAlarm extends Alarm {
    licenseId: LicenseType.DamageReportModule
    type: AlarmType.DamageReportIncorrectTransportObject
    alarmableType: 'App\\Models\\DamageReports\\Report'
    description: { transportObject: string | null; deviationId: uuid }
}

export interface CustomerBalanceLimitAlarm extends Alarm {
    licenseId: LicenseType.PackagingModule
    type: AlarmType.PackagingCustomerBalanceLimit
    alarmableType: 'App\\Models\\Company'
    description: { productId: uuid | null; customerId: uuid }
    productName?: string
    company: MinimalCompany
}

export interface DeviationCreatedAlarm extends Alarm {
    licenseId: LicenseType.DeliveryManagementModule
    type: AlarmType.DeliveryDeviationCreated
    alarmableType: 'App\\Models\\DeliveryManagement\\Deviation'
    tags: MinimalCompanyTag[]
}

export interface TicketCreatedAlarm extends Alarm {
    licenseId: LicenseType.DeliveryManagementModule
    type: AlarmType.DeliveryTicketCreated
    alarmableType: 'App\\Models\\DeliveryManagement\\Ticket'
    description: { initialState?: DeviationState; newState?: DeviationState }
    tags: MinimalCompanyTag[]
}

export interface TicketUpdatedAlarm extends Alarm {
    licenseId: LicenseType.DeliveryManagementModule
    type: AlarmType.DeliveryTicketUpdated
    alarmableType: 'App\\Models\\DeliveryManagement\\Ticket'
    description: { initialState?: DeviationState; newState?: DeviationState }
    tags: MinimalCompanyTag[]
}

export interface AlarmCounts {
    total: number
    byLicense: Record<number, number>
}

export interface AlarmNotification extends Model {
    id: uuid
    name: string
    companyId: uuid
    recipients: string[]
    alarms: Record<string, AlarmType[]>
    createdAt: string
}
